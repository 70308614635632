@import "colour";
@import "bootstrap";
@import "main";
@import "navbar";
@import "footer";

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2rem;
  }
}

#index-top-tile {
  margin: auto;
  margin-bottom: 100px;
  margin-top: -50px;
  max-width: 820px;
  width: 92%;

  h3 {
    margin: 5px 0;
  }

  p {
    color: $brand-shade4;

    strong {
      color: $brand-black;
    }
  }

  input,
  button {
    margin: auto;
  }

  .index-top-tile-col {
    padding: 20px;

    .button-text-combo {
      font-size: 1.1rem;
    }
  }

  .invalid-input {
    background-color: rgba($red-color, 0.8);
    border-radius: 0.2rem;
    color: $brand-white;
    font-size: 0.875rem;
    line-height: 1;
    margin-top: 0.1rem;
    max-width: 100%;
    padding: 0.5rem;
    position: absolute;
    z-index: 5;
    opacity: 1;
    transition: opacity 300ms;

    &.hide {
      opacity: 0;
    }
  }
}

.vorteil-div {
  padding: 20px;
}

.vorteil-img {
  display: block;
  height: 75px;
  margin: auto;
  margin-bottom: 15px;
  width: auto;
}

.vorteil-title {
  margin: 5px;
  text-align: center;
}

.vorteil-paragraph {
  color: $brand-shade4;
  margin: 0;
  text-align: center;
}

#ablauf {
  margin-top: 100px;
  text-align: center;

  > div {
    margin: auto;
    margin-bottom: 20px;
    max-width: 1400px;
    width: 96%;
  }

  h3,
  h4 {
    margin: 20px;
  }

  img {
    height: 100px;
    width: auto;
    margin: auto;
  }

  p {
    color: $brand-shade4;
    margin: 10px 5% 8px 5%;
  }
}

#features {
  margin: 20px auto 0 auto;
  max-width: 1400px;
  width: 96%;

  img {
    border-radius: 8px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
    display: block;
    margin: 20px auto;
    width: calc(100% - 20px);
    height: auto;
  }

  hr {
    margin: 0 -10px 6px -10px;
  }

  h3 {
    text-align: center;
  }

  p {
    color: $brand-shade4;
    margin: 0 0 8px 0;
  }

  .tile {
    height: 100%;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .row {
    div {
      margin-top: 1.5rem;
    }
  }
}

#preis {
  margin-top: 100px;
  text-align: center;

  #preisTag {
    font-size: 2.5rem;
  }

  #preisProFormular,
  #preisFlatrate {
    height: 350px;
  }

  h2 {
    margin: 20px;
  }

  > div {
    margin: auto;
    max-width: 800px;
    width: 96%;
  }

  .preisVorteile {
    margin: 30px 0;
  }

  .preisVorteil {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 20px;
      margin-right: 8px;
      width: auto;
    }

    p {
      margin: 5px 0;
    }
  }

  #preisFlatrate {
    background-color: $brand-black;
    color: $brand-white;
  }
}

#faq {
  margin: 0 auto;
  max-width: 1200px;
  width: 96%;
  text-align: center;

  h3 {
    margin: 20px 0;
  }

  .faqSet {
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;

    .faqQuestion {
      font-size: 1.1rem;
      margin-bottom: 0;
    }

    .faqAnswer {
      color: $brand-shade3;
      margin-top: 0;
    }
  }
}

#zitat {
  width: 96%;
  max-width: 820px;
  margin: 30px auto;
  text-align: center;
  padding: 30px;

  h3 {
    font-size: 1.8rem;
  }

  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;

    img {
      height: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .zitatName {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 30px;
  }

  .zitatTitel {
    margin-top: 0;
  }
}

#about {
  text-align: center;
  padding: 20px;

  > p {
    max-width: 800px;
    margin: 30px auto;
  }

  .aboutCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: $brand-white;

    > div {
      background-color: $brand-black;
      width: 260px;
      height: 320px;

      > img {
        height: 150px;
        border-radius: 1000px;
        width: auto;
      }
    }
  }

  .location {
    margin-top: 20px;

    img {
      height: 1rem;
      width: auto;
      filter: invert(1);
      vertical-align: middle;
    }

    p {
      font-size: 1rem;
    }
  }
}

#formDiv {
  margin: 2rem auto;
  max-width: 820px;
  padding: 40px;
  width: 96%;

  input {
    width: 100%;
  }

  .formItem {
    padding: 0 15px;
  }

  textarea {
    background-clip: padding-box;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: 7rem;
    line-height: 1.5;
    overflow: auto;
    padding: 0.375rem 0.75rem;
    resize: vertical;
    width: 100%;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    margin: 8px 15px 0 15px;
  }
}

#demozugang {
  text-align: center;
  width: 96%;
  max-width: 780px;
  margin: 0 auto;
  padding: 75px 0;

  > p {
    margin-bottom: 45px;
  }

  form {
    input {
      width: 100%;
      margin-bottom: 12px;
      font-size: 1.1rem;
      padding: 8px;
    }

    div {
      display: flex;
      justify-content: flex-end;
    }
  }
}

#projekt-wahl {
  background-color: $brand-black;
  color: $brand-white;
  text-align: center;
  width: 96%;
  max-width: 820px;
  margin: 40px auto;
  padding: 30px;

  h3 {
    font-size: 1.6rem;
    margin-bottom: 30px;
  }

  .pw-logoText {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 2.5rem;
      width: auto;
      cursor: pointer;
    }

    p {
      font-size: 2.5rem;
      cursor: pointer;
    }
  }

  .pw-link {
    margin: 50px auto;

    p {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: $brand-white;
      font-size: 1.4rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footImg {
    height: 1rem;
    width: auto;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
